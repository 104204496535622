import styles from "components/summary/summaryNode/summary-node.module.scss";
import {
  AddSquare20Regular as AddIcon,
  Edit20Regular as UpdateIcon,
  FlashCheckmark20Regular as Indicator,
  ArrowDownload20Regular as DownloadScbIcon,
} from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import { nodeStatus } from "apiTypes";
import Button, { ColorVariant, ExternalLinkButton, SizeVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import { useLocation, useNavigate } from "react-router-dom";
import { ADD_TORQ_NODE } from "constants/routes";
import { servicesConnectionStatus } from "features/connectionStatus/ConnectionStatusModal";
import { getRestEndpoint } from "utils/apiUrlBuilder";

export type SummaryNodeProps = {
  nodeId: number;
  nodeName: string;
  status: nodeStatus;
  children?: React.ReactNode;
  connectionStatus?: servicesConnectionStatus;
};

export function getNodeConnectionStatusButtonColor(
  status: nodeStatus,
  connectionStatus: servicesConnectionStatus | undefined,
) {
  if (status === nodeStatus.inactive) {
    return ColorVariant.disabled;
  }
  switch (connectionStatus) {
    case servicesConnectionStatus.desired:
      return ColorVariant.success;
    case servicesConnectionStatus.allMismatches:
      return ColorVariant.error;
    case servicesConnectionStatus.allInactive:
      return ColorVariant.error;
    case servicesConnectionStatus.someMismatches:
      return ColorVariant.warning;
    default:
      return ColorVariant.error;
  }
}

export default function SummaryNode(props: SummaryNodeProps) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const location = useLocation();
  const navigate = useNavigate();
  const restEndpoint = getRestEndpoint();

  return (
    <div className={styles.nodeSummaryContainer} data-intercom-target={"dashboard-node-container"}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <div className={styles.header} data-testid={"dashboard-node-name"}>
            {props.nodeName}
          </div>
        </div>
        <div className={styles.rightButtonControls}>
          <ExternalLinkButton
            href={`${restEndpoint}/lightning/emergencyRecover/${props.nodeId}`}
            buttonColor={ColorVariant.success}
            intercomTarget="download-emergency-recover"
            title={t.downloadEmergencyRecoverFile}
            icon={<DownloadScbIcon />}
            onClick={() => {
              track("Download Emergency Recover File");
            }}
          >
            {t.emergencyRecover}
          </ExternalLinkButton>
          <Button
            intercomTarget={"dashboard-node-connection-status"}
            icon={<Indicator />}
            buttonColor={getNodeConnectionStatusButtonColor(props.status, props.connectionStatus)}
            hideMobileText={true}
            onClick={() => {
              track("Navigate to Node Connection Status");
              navigate("/node-connection-status?nodeId=" + props.nodeId, { state: { background: location } });
            }}
          >
            {t.connectionStatus.status}
          </Button>
          <Button
            intercomTarget={"dashboard-node-settings"}
            icon={<UpdateIcon />}
            buttonColor={ColorVariant.success}
            hideMobileText={true}
            onClick={() => {
              track("Navigate to Update Node");
              navigate("/node/" + props.nodeId, { state: { background: location } });
            }}
          >
            {t.update}
          </Button>
        </div>
      </div>

      {props.status === nodeStatus.active && props.children}
    </div>
  );
}

export function AddNodes({ noNodes }: { noNodes: boolean }) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className={styles.nodeSummaryContainer}>
      <div className={styles.addNodeBody}>
        {noNodes && (
          <>
            <div className={styles.welcomeHeading}> {t.Welcome + "!"}</div>
            <div className={styles.welcomeText}>{t.AddANodeToGetStarted}</div>
          </>
        )}
        <Button
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.large}
          onClick={() => {
            track("Navigate to Add Node", { location: location?.pathname });
            navigate(ADD_TORQ_NODE, { state: { background: location } });
          }}
          icon={<AddIcon />}
          id={"dashboard-add-node-button"}
          intercomTarget={"dashboard-add-node-button"}
        >
          {t.addNode}
        </Button>
      </div>
    </div>
  );
}
