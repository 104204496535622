import React, { useState } from "react";
import {
  ChevronDown20Regular as CollapsedIcon,
  Delete20Regular as DeleteIcon,
  Delete24Regular as DeleteIconHeader,
  LineHorizontal120Regular as ExpandedIcon,
  Pause20Regular as PauseIcon,
  Play20Regular as PlayIcon,
  Save20Regular as SaveIcon,
  PlugConnected20Regular as TestPluginConnectionIcon,
} from "@fluentui/react-icons";
import styles from "features/settings/NodeSettings.module.scss";
import Spinny from "features/spinny/Spinny";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import File from "components/forms/file/File";
import Input from "components/forms/input/Input";
import {
  torqApi,
  useAddNodeConfigurationMutation,
  useGetNodeConfigurationQuery,
  useTestClnPluginConnectionMutation,
  useUpdateCustomSettingsMutation,
  useUpdateNodeConfigurationMutation,
  useUpdateNodeConfigurationStatusMutation,
} from "apiSlice";
import { nodeConfiguration } from "apiTypes";
import classNames from "classnames";
import Collapse from "features/collapse/Collapse";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import Modal from "features/modal/Modal";
import Switch from "components/forms/switch/Switch";
import useTranslations from "services/i18n/useTranslations";
import { Form, InputRow, Select } from "components/forms/forms";
import Note, { NoteType } from "features/note/Note";
import ErrorSummary from "components/errors/ErrorSummary";
import { FormErrors, ServerErrorResultType, ServerErrorType, mergeServerError } from "components/errors/errors";
import { format } from "date-fns";
import { useAppDispatch } from "store/hooks";
import { userEvents } from "utils/userEvents";
import { components, OptionProps, SingleValueProps } from "react-select";
import PropTypes from "prop-types";
import { IsNumericOption, IsStringOption } from "utils/typeChecking";

interface nodeProps {
  nodeId: number;
  collapsed?: boolean;
  addMode?: boolean;
  onAddSuccess?: () => void;
  onAddFailure?: () => void;
  handleClose: () => void;
}

type ColourOption = {
  label: string;
  value: string;
};

const colourOptions: ColourOption[] = [
  { label: "Purple", value: "#BA93FA" },
  { label: "Blue", value: "#85C4FF" },
  { label: "Red", value: "#E18484" },
  { label: "Green", value: "#2DC4BE" },
  { label: "Gold", value: "#E1D4B7" },
  { label: "Turquoise", value: "#84CEE1" },
  { label: "Magenta", value: "#C875B6" },
];

const nodeConfigurationTemplate = {
  createdOn: undefined,
  grpcAddress: "",
  macaroonFileName: "",
  name: "",
  tlsFileName: "",
  caCertificateFileName: "",
  certificateFileName: "",
  keyFileName: "",
  updatedOn: undefined,
  implementation: 0,
  nodeId: 0,
  status: 0,
  pingSystem: 0,
  customSettings: 0,
  nodeStartDate: undefined,
  nodeCssColour: colourOptions[0].value,
  apiHandle: "",
  clnPluginEnabled: false,
  clnPluginGrpcAddress: "",
};

const importFailedPayments = "importFailedPayments";
const importFailedPaymentsValue = 1;
const importHtlcEvents = "importHtlcEvents";
const importHtlcEventsValue = 2;
// PeerEvents has been removed as an option
const importTransactions = "importTransactions";
const importTransactionsValue = 8;
const importPayments = "importPayments";
const importPaymentsValue = 16;
const importInvoices = "importInvoices";
const importInvoicesValue = 32;
const importForwards = "importForwards";
const importForwardsValue = 64;
const importForwardsHistory = "importForwardsHistory";
const importForwardsHistoryValue = 128;
const interceptHtlc = "interceptHtlc";
const interceptHtlcValue = 256;
const interceptChannelOpen = "interceptChannelOpen";
const interceptChannelOpenValue = 512;

const defaultCustomSettingsByImplementation = new Map<number, number>([
  // LND
  [
    0,
    importHtlcEventsValue +
      importTransactionsValue +
      importPaymentsValue +
      importInvoicesValue +
      importForwardsHistoryValue +
      importForwardsValue +
      interceptHtlcValue,
  ],
  // CLN
  [
    1,
    importTransactionsValue +
      importPaymentsValue +
      importInvoicesValue +
      importForwardsValue +
      importForwardsHistoryValue,
  ],
]);

// Disable some services that are not supported by the implementation
const disabledCustomSettingsByImplementation = new Map<number, Array<string>>([
  // LND
  [0, []],
  // CLN
  [1, [importHtlcEvents, interceptHtlc]],
]);

interface importProps {
  value: number;
  label?: string;
}

const colourSelectSingleValue = ({ ...props }: SingleValueProps<unknown>) => {
  const colourOption: ColourOption = props.data as ColourOption;
  return (
    <components.SingleValue {...props}>
      <div className={styles.colourSelectContainer}>
        <div className={styles.colourSelect} style={{ backgroundColor: colourOption.value }}></div>
      </div>
    </components.SingleValue>
  );
};

colourSelectSingleValue.propTypes = {
  data: PropTypes.object.isRequired,
};

const colourSelectOption = (props: OptionProps) => {
  const colourOption = props.data as ColourOption;
  return (
    <components.Option {...props}>
      <div className={styles.colourSelectContainer}>
        <div className={styles.colourSelect} style={{ backgroundColor: colourOption.value }}></div>
      </div>
    </components.Option>
  );
};

colourSelectOption.propTypes = {
  data: PropTypes.object.isRequired,
};

const NodeSettings = React.forwardRef(function NodeSettings(
  { nodeId, addMode, onAddSuccess, handleClose }: nodeProps,
  ref,
) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const toastRef = React.useContext(ToastContext);
  const popoverRef = React.useRef();

  const { data: nodeConfigurationData } = useGetNodeConfigurationQuery(nodeId, {
    skip: !nodeId || nodeId == 0,
  });
  const [updateNodeConfiguration] = useUpdateNodeConfigurationMutation();
  const [addNodeConfiguration] = useAddNodeConfigurationMutation();
  const [setNodeConfigurationStatus] = useUpdateNodeConfigurationStatusMutation();
  const [setCustomSettings] = useUpdateCustomSettingsMutation();
  const [testClnPluginConnection] = useTestClnPluginConnectionMutation();

  const [nodeConfigurationState, setNodeConfigurationState] = useState<nodeConfiguration>(nodeConfigurationTemplate);
  const [customSettingsCollapsedState, setCustomSettingsCollapsedState] = useState<boolean>(!addMode);
  const [customSettingsSaveEnabledState, setCustomSettingsSaveEnabledState] = useState(false);
  const [customSettingsSavingState, setCustomSettingsSavingState] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const [deleteConfirmationTextInputState, setDeleteConfirmationTextInputState] = useState("");
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const [saveEnabledState, setSaveEnabledState] = useState(true);
  const [enableEnableButtonState, setEnableEnableButtonState] = useState(true);
  const [formErrorState, setFormErrorState] = React.useState({} as FormErrors);
  const [toggleErrorState, setToggleErrorState] = React.useState({} as FormErrors);
  const [tlsEnabledState, setTlsEnabledState] = useState(true);
  const [macaroonEnabledState, setMacaroonEnabledState] = useState(true);
  const [caCertificateEnabledState, setCaCertificateEnabledState] = useState(false);
  const [certificateEnabledState, setCertificateEnabledState] = useState(false);
  const [keyEnabledState, setKeyEnabledState] = useState(false);

  const dispatch = useAppDispatch();

  const customSettingsSidebarData = new Map<string, importProps>([
    [importTransactions, { value: importTransactionsValue, label: t.importTransactions }],
    [importPayments, { value: importPaymentsValue, label: t.importPayments }],
    [importInvoices, { value: importInvoicesValue, label: t.importInvoices }],
    [importForwardsHistory, { value: importForwardsHistoryValue, label: t.importForwardsHistory }],
    [importForwards, { value: importForwardsValue, label: t.importForwards }],
    [importHtlcEvents, { value: importHtlcEventsValue, label: t.importHtlcEvents }],
    [interceptHtlc, { value: interceptHtlcValue, label: t.interceptHtlc }],
    [importFailedPayments, { value: importFailedPaymentsValue, label: undefined }],
    [interceptChannelOpen, { value: interceptChannelOpenValue, label: t.interceptChannelOpen }],
  ]);

  function disableDisabledCustomSettings(customSettings: number, implementation: number) {
    disabledCustomSettingsByImplementation.get(implementation)?.forEach((key) => {
      const value = customSettingsSidebarData.get(key)?.value;
      if (value) {
        // bitwise remove
        customSettings &= ~value;
      }
    });

    return customSettings;
  }

  React.useImperativeHandle(ref, () => ({
    clear() {
      clear();
    },
  }));

  const clear = () => {
    setNodeConfigurationState({
      grpcAddress: "",
      nodeId: 0,
      status: 0,
      implementation: 0,
      pingSystem: 0,
      name: "",
      customSettings: 0,
      nodeStartDate: undefined,
      apiHandle: "",
    } as nodeConfiguration);
  };

  const handleConfirmationModalClose = () => {
    setShowModalState(false);
    setDeleteConfirmationTextInputState("");
  };

  const handleDeleteClick = () => {
    if (popoverRef.current) {
      (popoverRef.current as { close: () => void }).close();
    }
    setShowModalState(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submitNodeSettings();
  };

  const submitNodeSettings = async () => {
    setSaveEnabledState(false);
    const form = new FormData();
    form.append("implementation", "" + nodeConfigurationState.implementation);
    form.append("name", nodeConfigurationState.name ?? "");
    form.append("apiHandle", nodeConfigurationState.apiHandle ?? "");
    form.append("nodeId", "" + nodeConfigurationState.nodeId);
    form.append("status", "" + nodeConfigurationState.status);
    form.append("pingSystem", "" + nodeConfigurationState.pingSystem);
    form.append("customSettings", "" + nodeConfigurationState.customSettings);
    form.append("grpcAddress", nodeConfigurationState.grpcAddress ?? "");
    if (nodeConfigurationState.nodeCssColour) {
      form.append("nodeCssColour", nodeConfigurationState.nodeCssColour);
    }
    if (nodeConfigurationState.tlsFile) {
      form.append("tlsFile", nodeConfigurationState.tlsFile, nodeConfigurationState.tlsFileName);
    }
    if (nodeConfigurationState.macaroonFile) {
      form.append("macaroonFile", nodeConfigurationState.macaroonFile, nodeConfigurationState.macaroonFileName);
    }
    if (nodeConfigurationState.caCertificateFile) {
      form.append(
        "caCertificateFile",
        nodeConfigurationState.caCertificateFile,
        nodeConfigurationState.caCertificateFileName,
      );
    }
    if (nodeConfigurationState.certificateFile) {
      form.append(
        "certificateFile",
        nodeConfigurationState.certificateFile,
        nodeConfigurationState.certificateFileName,
      );
    }
    if (nodeConfigurationState.keyFile) {
      form.append("keyFile", nodeConfigurationState.keyFile, nodeConfigurationState.keyFileName);
    }
    if (nodeConfigurationState.nodeStartDate) {
      form.append("nodeStartDate", "" + formatDate(nodeConfigurationState.nodeStartDate));
    }
    form.append("clnPluginEnabled", "" + (nodeConfigurationState.clnPluginEnabled || false));
    if (nodeConfigurationState.clnPluginGrpcAddress) {
      form.append("clnPluginGrpcAddress", nodeConfigurationState.clnPluginGrpcAddress);
    }

    // we are adding new node
    if (!nodeConfigurationState.nodeId || nodeConfigurationState.nodeId == 0) {
      addNodeConfiguration(form)
        .unwrap()
        .then((_) => {
          setFormErrorState({} as FormErrors);
          setSaveEnabledState(true);
          setEnableEnableButtonState(true);
          toastRef?.current?.addToast("Local node added", toastCategory.success);
          if (onAddSuccess) {
            onAddSuccess();
          }
          handleClose();
        })
        .catch((error) => {
          setSaveEnabledState(true);
          /* toastRef?.current?.addToast(error.data["errors"]["server"][0].split(":")[0], toastCategory.error); */
          const mergedErrors = mergeServerError(error.data, formErrorState);
          setFormErrorState(mergedErrors);
        });
      track("Add Local Node");
      return;
    } else {
      updateNodeConfiguration(form)
        .unwrap()
        .then((_) => {
          setFormErrorState({} as FormErrors);
          setSaveEnabledState(true);
          toastRef?.current?.addToast("Local node info saved", toastCategory.success);
        })
        .catch((error) => {
          setSaveEnabledState(true);
          /* toastRef?.current?.addToast(error.data["errors"]["server"][0].split(":")[0], toastCategory.error); */
          const mergedErrors = mergeServerError(error.data, formErrorState);
          setFormErrorState(mergedErrors);
        });
      track("Update Local Node", { nodeId: nodeConfigurationState.nodeId });
    }
    dispatch(torqApi.util.resetApiState());
  };

  const submitCustomSettings = async () => {
    setCustomSettingsSavingState(true);
    setCustomSettingsSaveEnabledState(false);
    setCustomSettings({
      nodeId: nodeConfigurationState.nodeId,
      customSettings: nodeConfigurationState.customSettings,
      pingSystems: nodeConfigurationState.pingSystem,
    })
      .unwrap()
      .then((_) => {
        setToggleErrorState({} as FormErrors);
        setCustomSettingsSavingState(false);
        setCustomSettingsSaveEnabledState(true);
        toastRef?.current?.addToast("Custom Settings Saved", toastCategory.success);
      })
      .catch((error) => {
        /* toastRef?.current?.addToast(error.data["errors"]["server"][0].split(":")[0], toastCategory.error); */
        const mergedErrors = mergeServerError(error.data, formErrorState);
        setToggleErrorState(mergedErrors);
        setCustomSettingsSavingState(false);
        setCustomSettingsSaveEnabledState(true);
      });
    track("Save Custom Settings");
    dispatch(torqApi.util.resetApiState());
  };

  React.useEffect(() => {
    const ncs = nodeConfigurationData || nodeConfigurationTemplate;
    if (nodeConfigurationData == undefined && !customSettingsSavingState) {
      // do not update custom settings if the save is in progress
      // default custom settings
      ncs.customSettings = defaultCustomSettingsByImplementation.get(ncs.implementation) || 0;
      // disable the disabled ones just in case (should already be disabled in the defaults)
      ncs.customSettings = disableDisabledCustomSettings(ncs.customSettings, ncs.implementation);
    }
    setNodeConfigurationState(ncs);
    setTlsEnabledState(true);
    setMacaroonEnabledState(true);
    setCaCertificateEnabledState(false);
    setCertificateEnabledState(false);
    setKeyEnabledState(false);
    if (nodeConfigurationData != undefined && nodeConfigurationData.status == 0) {
      setSaveEnabledState(true);
    }
    if (nodeConfigurationData != undefined && nodeConfigurationData.implementation == 1) {
      setTlsEnabledState(false);
      setMacaroonEnabledState(false);
      setCaCertificateEnabledState(true);
      setCertificateEnabledState(true);
      setKeyEnabledState(true);
    }
  }, [nodeConfigurationData]);

  const toggleCustomSettingsState = (key: string) => {
    setCustomSettingsSaveEnabledState(true); // can save now because data changed
    const data = customSettingsSidebarData.get(key);
    if (data !== undefined) {
      // bitwise toggle operation
      let cs = nodeConfigurationState.customSettings;
      cs ^= data.value;
      setNodeConfigurationState({
        ...nodeConfigurationState,
        customSettings: cs,
      });
    }
  };

  const handleTLSFileChange = (file: File | null) => {
    setNodeConfigurationState({
      ...nodeConfigurationState,
      tlsFile: file,
      tlsFileName: file ? file.name : undefined,
    });
  };
  const handleMacaroonFileChange = (file: File | null) => {
    setNodeConfigurationState({
      ...nodeConfigurationState,
      macaroonFile: file,
      macaroonFileName: file ? file.name : undefined,
    });
  };
  const handleCaCertificateFileChange = (file: File | null) => {
    setNodeConfigurationState({
      ...nodeConfigurationState,
      caCertificateFile: file,
      caCertificateFileName: file ? file.name : undefined,
    });
  };
  const handleCertificateFileChange = (file: File | null) => {
    setNodeConfigurationState({
      ...nodeConfigurationState,
      certificateFile: file,
      certificateFileName: file ? file.name : undefined,
    });
  };
  const handleKeyFileChange = (file: File | null) => {
    setNodeConfigurationState({
      ...nodeConfigurationState,
      keyFile: file,
      keyFileName: file ? file.name : undefined,
    });
  };

  const handleAddressChange = (value: string) => {
    setNodeConfigurationState({ ...nodeConfigurationState, grpcAddress: value });
  };

  const handleNodeNameChange = (value: string) => {
    setNodeConfigurationState({ ...nodeConfigurationState, name: value });
  };

  const handleNodeApiHandleChange = (value: string) => {
    setNodeConfigurationState({ ...nodeConfigurationState, apiHandle: value });
  };

  const handleCustomSettingsCollapseClick = () => {
    setCustomSettingsCollapsedState(!customSettingsCollapsedState);
  };

  const handleModalDeleteClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModalState(false);
    setDeleteConfirmationTextInputState("");
    setDeleteEnabled(false);
    track("Delete Local Node", { nodeId: nodeConfigurationState.nodeId });
    setNodeConfigurationStatus({ nodeId: nodeConfigurationState.nodeId, status: 3 })
      .unwrap()
      .then((_) => {
        toastRef?.current?.addToast("Node deleted", toastCategory.success);
      })
      .catch((error: ServerErrorResultType) => {
        const err = mergeServerError(error.data as ServerErrorType, {});
        if (err?.server && err.server.length > 0) {
          for (const se of err.server) {
            if (se.description !== undefined && se.description !== "") {
              toastRef?.current?.addToast(se.description || "", toastCategory.error);
            }
          }
        }
      })
      .finally(() => {
        dispatch(torqApi.util.resetApiState());
      });
    handleClose();
  };

  const handleDeleteConfirmationTextInputChange = (value: string) => {
    setDeleteConfirmationTextInputState(value as string);
    setDeleteEnabled(value.toLowerCase() === "delete");
  };

  const handleStatusClick = () => {
    setEnableEnableButtonState(false);
    setSaveEnabledState(false);
    let statusId = 0;
    if (nodeConfigurationState.status == 0) {
      statusId = 1;
      track("Enable Local Node", { nodeId: nodeConfigurationState.nodeId });
    } else {
      track("Disable Local Node", { nodeId: nodeConfigurationState.nodeId });
    }
    setNodeConfigurationStatus({ nodeId: nodeConfigurationState.nodeId, status: statusId })
      .unwrap()
      .then((_) => {
        toastRef?.current?.addToast("Node status changed", toastCategory.success);
      })
      .catch((error: ServerErrorResultType) => {
        console.log(error);
        const err = mergeServerError(error.data as ServerErrorType, {});
        if (err?.server && err.server.length > 0) {
          for (const se of err.server) {
            if (se.description !== undefined && se.description !== "") {
              toastRef?.current?.addToast(se.description || "", toastCategory.error);
            }
          }
        }
      })
      .finally(() => {
        setEnableEnableButtonState(true);
        setSaveEnabledState(true);
        dispatch(torqApi.util.resetApiState());
      });
    if (popoverRef.current) {
      (popoverRef.current as { close: () => void }).close();
    }
  };

  const handleAmbossPingClick = () => {
    setCustomSettingsSaveEnabledState(true);
    const pingSystem = 1;
    if (nodeConfigurationState.pingSystem % (pingSystem * 2) >= pingSystem) {
      setNodeConfigurationState({
        ...nodeConfigurationState,
        pingSystem: nodeConfigurationState.pingSystem - pingSystem,
      });
    } else {
      setNodeConfigurationState({
        ...nodeConfigurationState,
        pingSystem: nodeConfigurationState.pingSystem + pingSystem,
      });
    }
  };

  const handleVectorPingClick = () => {
    setCustomSettingsSaveEnabledState(true);
    const pingSystem = 2;
    if (nodeConfigurationState.pingSystem % (pingSystem * 2) >= pingSystem) {
      setNodeConfigurationState({
        ...nodeConfigurationState,
        pingSystem: nodeConfigurationState.pingSystem - pingSystem,
      });
    } else {
      setNodeConfigurationState({
        ...nodeConfigurationState,
        pingSystem: nodeConfigurationState.pingSystem + pingSystem,
      });
    }
  };

  const handleNodeStartDateChange = (value: string) => {
    // use Date.parse to get utc date
    const date = new Date(Date.parse(value));
    if (!Number.isNaN(date.valueOf()) && date.valueOf() !== 0) {
      setNodeConfigurationState({ ...nodeConfigurationState, nodeStartDate: date });
    } else {
      setNodeConfigurationState({ ...nodeConfigurationState, nodeStartDate: undefined });
    }
  };

  const handleClnPluginAddressChange = (value: string) => {
    setNodeConfigurationState({ ...nodeConfigurationState, clnPluginGrpcAddress: value });
  };

  const formatDate = (date: Date | undefined) => {
    if (date != undefined && !Number.isNaN(date.valueOf()) && date.valueOf() !== 0) {
      return format(new Date(date.valueOf()), "yyyy-MM-dd");
    }
    return "";
  };

  const implementationOptions = [
    { value: 0, label: "LND" },
    { value: 1, label: "CLN" },
  ];

  const handleNodeCssColourChange = (value: string) => {
    setNodeConfigurationState({ ...nodeConfigurationState, nodeCssColour: value });
  };

  const clnPluginConnectionTest = () => {
    if (nodeConfigurationState.clnPluginGrpcAddress == "") {
      toastRef?.current?.addToast("Please enter a CLN plugin address", toastCategory.error);
      return;
    }

    testClnPluginConnection({
      nodeId: nodeConfigurationState.nodeId,
      grpcAddress: nodeConfigurationState.clnPluginGrpcAddress || "",
    })
      .unwrap()
      .then((res) => {
        let toastMsg = "CLN plugin connection successful. ";
        let toastCat = toastCategory.success;

        if (!res.connectionOk) {
          toastMsg = "CLN plugin connection failed. ";
          toastCat = toastCategory.error;
        }

        if (res.error) {
          toastMsg += res.error;

          if (res.connectionOk) {
            toastCat = toastCategory.warn;
          }
        }

        toastRef?.current?.addToast(toastMsg, toastCat);
      })
      .catch((error) => {
        toastRef?.current?.addToast("Error in CLN plugin connection test: " + String(error), toastCategory.error);
      });
  };

  return (
    <>
      <div className={""}>
        <Form onSubmit={handleSubmit} intercomTarget={"node-configuration-form"}>
          <Select
            intercomTarget={"node-setting-implementation"}
            inputId={"node-setting-implementation"}
            label={t.implementation}
            onChange={(newValue: unknown) => {
              if (!IsNumericOption(newValue)) return;

              if (newValue.value == 0) {
                setTlsEnabledState(true);
                setMacaroonEnabledState(true);
                setCaCertificateEnabledState(false);
                setCertificateEnabledState(false);
                setKeyEnabledState(false);
              } else if (newValue.value == 1) {
                setTlsEnabledState(false);
                setMacaroonEnabledState(false);
                setCaCertificateEnabledState(true);
                setCertificateEnabledState(true);
                setKeyEnabledState(true);
              }
              setNodeConfigurationState({
                ...nodeConfigurationState,
                implementation: newValue.value,
                // reset to default when changing implementation
                customSettings: disableDisabledCustomSettings(
                  defaultCustomSettingsByImplementation.get(newValue.value) || 0,
                  newValue.value,
                ),
              });
            }}
            options={implementationOptions}
            isDisabled={!saveEnabledState || nodeConfigurationState.status == 1}
            value={implementationOptions.find((io) => io.value == nodeConfigurationState.implementation)}
          />
          {nodeConfigurationState.implementation == 1 && (
            <>
              <Note intercomTarget={"node-setting-note-cln"} title={t.clnExperimental} noteType={NoteType.warning}>
                {t.clnNote}
              </Note>
              <Note
                intercomTarget={"node-setting-note-rebalance-cln"}
                title={t.clnExperimental}
                noteType={NoteType.warning}
              >
                {t.clnRebalanceNote}
              </Note>
            </>
          )}
          <span id="name">
            <Input
              intercomTarget={"node-setting-input-name"}
              label={t.nodeName}
              value={nodeConfigurationState.name}
              type={"text"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNodeNameChange(e.target.value)}
              placeholder="Node 1"
              disabled={!saveEnabledState || nodeConfigurationState.status == 1}
            />
          </span>
          <span id="apiHandle">
            <Input
              intercomTarget={"node-setting-input-apiHandle"}
              label={t.apiHandle}
              value={nodeConfigurationState.apiHandle}
              type={"text"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNodeApiHandleChange(e.target.value)}
              placeholder="Node_1"
              disabled={!saveEnabledState || nodeConfigurationState.status == 1}
            />
          </span>
          <span id="address">
            <Input
              intercomTarget={"node-setting-input-address"}
              label={t.grpcAddress}
              type={"text"}
              value={nodeConfigurationState.grpcAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e.target.value)}
              placeholder="100.100.100.100:10009"
              name="grpcAddress"
              disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              errors={formErrorState}
            />
          </span>
          {tlsEnabledState && (
            <span id="tls">
              <File
                intercomTarget={"node-setting-input-tls"}
                label={t.tlsCertificate}
                onFileChange={handleTLSFileChange}
                fileName={nodeConfigurationState?.tlsFileName}
                disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              />
            </span>
          )}
          {macaroonEnabledState && (
            <span id="macaroon">
              <File
                intercomTarget={"node-setting-input-macaroon"}
                label={t.macaroon}
                onFileChange={handleMacaroonFileChange}
                fileName={nodeConfigurationState?.macaroonFileName}
                disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              />
            </span>
          )}
          {caCertificateEnabledState && (
            <span id="caCertificate">
              <File
                intercomTarget={"node-setting-input-ca"}
                label={t.caCertificate}
                onFileChange={handleCaCertificateFileChange}
                fileName={nodeConfigurationState?.caCertificateFileName}
                disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              />
            </span>
          )}
          {certificateEnabledState && (
            <span id="certificate">
              <File
                intercomTarget={"node-setting-input-client"}
                label={t.certificate}
                onFileChange={handleCertificateFileChange}
                fileName={nodeConfigurationState?.certificateFileName}
                disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              />
            </span>
          )}
          {keyEnabledState && (
            <span id="key">
              <File
                intercomTarget={"node-setting-input-clientKey"}
                label={t.key}
                onFileChange={handleKeyFileChange}
                fileName={nodeConfigurationState?.keyFileName}
                disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              />
            </span>
          )}
          <span id="nodeStartDate">
            <Input
              intercomTarget={"node-setting-input-nodeStartDate"}
              label={t.nodeStartDate}
              value={formatDate(nodeConfigurationState.nodeStartDate)}
              max={formatDate(new Date())}
              type={"date"}
              disabled={!saveEnabledState || nodeConfigurationState.status == 1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNodeStartDateChange(e.target.value)}
            />
          </span>
          <Select
            name={"nodeCssColour"}
            inputId={"node-setting-input-color"}
            // helpText={t.nodeColorHelpText}
            intercomTarget="node-setting-input-color"
            selectComponents={{ Option: colourSelectOption, SingleValue: colourSelectSingleValue }}
            label={t.nodeColor}
            onChange={(newValue: unknown) => {
              if (!IsStringOption(newValue)) return;
              handleNodeCssColourChange(newValue.value);
            }}
            options={colourOptions}
            errors={formErrorState}
            isDisabled={!saveEnabledState || nodeConfigurationState.status == 1}
            value={
              colourOptions.find((option) => option.value === nodeConfigurationState.nodeCssColour) || colourOptions[0]
            }
          />
          {nodeConfigurationState.implementation == 1 && (
            <>
              <span id="clnPluginEnabled">
                <InputRow>
                  <Switch
                    intercomTarget={"node-setting-input-use-cln-plugin-switch"}
                    label={t.useClnPlugin}
                    checked={nodeConfigurationState.clnPluginEnabled}
                    onChange={() => {
                      setNodeConfigurationState({
                        ...nodeConfigurationState,
                        clnPluginEnabled: !nodeConfigurationState.clnPluginEnabled,
                      });
                    }}
                    disabled={!saveEnabledState || nodeConfigurationState.status == 1}
                  />
                  <Button
                    intercomTarget={"clnPluginTestButton"}
                    type={"button"}
                    icon={<TestPluginConnectionIcon />}
                    onClick={clnPluginConnectionTest}
                    disabled={!nodeConfigurationState.clnPluginEnabled || !nodeConfigurationState.clnPluginGrpcAddress}
                  >
                    {t.testClnPluginConnection}
                  </Button>
                </InputRow>
              </span>
              <span id="clnPluginGrpcAddress">
                <Input
                  intercomTarget={"node-setting-input-cln-plugin-address"}
                  label={t.clnPluginGrpcAddress}
                  value={nodeConfigurationState.clnPluginGrpcAddress}
                  type={"text"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleClnPluginAddressChange(e.target.value)}
                  placeholder="100.100.100.100:50053"
                  disabled={
                    !saveEnabledState || nodeConfigurationState.status == 1 || !nodeConfigurationState.clnPluginEnabled
                  }
                  helpText={t.clnPluginGrpcAddressHelp}
                />
              </span>
              <Note
                intercomTarget={"node-setting-note-cln-plugin"}
                title={t.clnPluginNoteTitle}
                noteType={NoteType.info}
              >
                {t.clnPluginNote}
              </Note>
            </>
          )}
          {addMode && (
            <div className={styles.customImportSettings}>
              <div
                className={classNames(styles.header, { [styles.expanded]: !customSettingsCollapsedState })}
                onClick={handleCustomSettingsCollapseClick}
              >
                <div className={styles.title}>{t.advancedSettings}</div>
                <div className={classNames(styles.collapseIcon, { [styles.collapsed]: customSettingsCollapsedState })}>
                  {customSettingsCollapsedState ? <CollapsedIcon /> : <ExpandedIcon />}
                </div>
              </div>
              <Collapse collapsed={customSettingsCollapsedState} animate={true}>
                <div className={styles.customImportSettingsBody}>
                  {Array.from(customSettingsSidebarData).map((item) => {
                    const key = item[0];
                    const data = item[1];
                    if (
                      data !== undefined &&
                      data.label !== undefined &&
                      // don't show if disabled for implementation
                      !disabledCustomSettingsByImplementation.get(nodeConfigurationState.implementation)?.includes(key)
                    ) {
                      return (
                        <div className={styles.import} key={key}>
                          <Switch
                            intercomTarget={"node-setting-input-" + key + "-switch"}
                            label={data.label}
                            checked={nodeConfigurationState.customSettings & data.value ? true : false}
                            onChange={() => {
                              toggleCustomSettingsState(key);
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                  <Switch
                    label={t.importFailedPayments}
                    intercomTarget={"node-setting-input-importFailedPayments-switch"}
                    checked={
                      nodeConfigurationState.customSettings % (importFailedPaymentsValue * 2) >=
                      importFailedPaymentsValue
                    }
                    onChange={() => {
                      toggleCustomSettingsState(importFailedPayments);
                    }}
                  />
                  <div className={styles.importFailedPayments}>
                    <Note title={"Failed Payments"} noteType={NoteType.warning}>
                      {t.info.importFailedPayments}
                    </Note>
                  </div>
                </div>
              </Collapse>
            </div>
          )}
          <ErrorSummary errors={formErrorState} />
          {!addMode && (
            <ButtonRow>
              <Button
                type={"button"}
                intercomTarget={"node-configuration-disable-button"}
                buttonPosition={ButtonPosition.fullWidth}
                buttonColor={ColorVariant.warning}
                icon={nodeConfigurationState.status == 0 ? <PlayIcon /> : <PauseIcon />}
                onClick={handleStatusClick}
                disabled={!enableEnableButtonState}
              >
                {nodeConfigurationState.status == 0 ? "Enable node" : "Disable node"}
              </Button>
              <Button
                type={"button"}
                intercomTarget={"node-configuration-delete-button"}
                buttonColor={ColorVariant.error}
                icon={<DeleteIcon />}
                onClick={handleDeleteClick}
                buttonPosition={ButtonPosition.fullWidth}
              >
                {"Delete node"}
              </Button>
            </ButtonRow>
          )}
          <Button
            id={"save-node"}
            intercomTarget={"node-setting-save-button"}
            buttonColor={ColorVariant.success}
            icon={saveEnabledState || nodeConfigurationState.status == 1 ? <SaveIcon /> : <Spinny />}
            onClick={submitNodeSettings}
            buttonPosition={ButtonPosition.fullWidth}
            disabled={!saveEnabledState || nodeConfigurationState.status == 1}
          >
            {addMode
              ? "Add Node"
              : nodeConfigurationState.status == 1
              ? "Disable node to update"
              : saveEnabledState
              ? "Save node details"
              : "Saving..."}
          </Button>
          {!addMode && (
            <div className={styles.toggleSettings} data-intercom-target={"node-setting-advanced-toggle-button"}>
              <div
                className={classNames(styles.header, { [styles.expanded]: !customSettingsCollapsedState })}
                onClick={handleCustomSettingsCollapseClick}
              >
                <div className={styles.title}>{t.advancedSettings}</div>
                <div className={classNames(styles.collapseIcon, { [styles.collapsed]: customSettingsCollapsedState })}>
                  {customSettingsCollapsedState ? <CollapsedIcon /> : <ExpandedIcon />}
                </div>
              </div>
              <Collapse
                collapsed={customSettingsCollapsedState}
                animate={true}
                intercomTarget={"node-setting-advanced-options-addmode"}
              >
                <div className={styles.customImportSettingsBody}>
                  {Array.from(customSettingsSidebarData).map((item) => {
                    const key = item[0];
                    const data = item[1];
                    if (
                      data !== undefined &&
                      data.label !== undefined &&
                      // don't show if disabled for implementation
                      !disabledCustomSettingsByImplementation.get(nodeConfigurationState.implementation)?.includes(key)
                    ) {
                      return (
                        <div className={styles.import} key={key}>
                          <Switch
                            intercomTarget={"node-setting-input-" + key + "-switch-addmode"}
                            label={data.label || ""}
                            checked={nodeConfigurationState.customSettings & data.value ? true : false}
                            onChange={() => {
                              toggleCustomSettingsState(key);
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                  <Switch
                    intercomTarget={"node-setting-input-importFailedPayments-switch-addmode"}
                    label={t.importFailedPayments}
                    checked={
                      nodeConfigurationState.customSettings % (importFailedPaymentsValue * 2) >=
                      importFailedPaymentsValue
                    }
                    onChange={() => {
                      toggleCustomSettingsState(importFailedPayments);
                    }}
                  />
                  <Switch
                    intercomTarget={"node-setting-input-vectorPing-switch-addmode"}
                    label="Vector Ping"
                    checked={nodeConfigurationState.pingSystem % 4 >= 2}
                    onChange={handleVectorPingClick}
                  />
                  <Switch
                    intercomTarget={"node-setting-input-ambossPing-switch-addmode"}
                    label="Amboss Ping"
                    checked={nodeConfigurationState.pingSystem % 2 >= 1}
                    onChange={handleAmbossPingClick}
                  />
                  <ErrorSummary errors={toggleErrorState} />
                  <Button
                    intercomTarget={"node-setting-advanced-options-save-button-addmode"}
                    id={"customSettings-save-node"}
                    buttonColor={ColorVariant.success}
                    icon={customSettingsSavingState ? <Spinny /> : <SaveIcon />}
                    onClick={submitCustomSettings}
                    buttonPosition={ButtonPosition.fullWidth}
                    /* do not allow changing services when node disabled*/
                    disabled={!customSettingsSaveEnabledState || nodeConfigurationState.status == 0}
                  >
                    {customSettingsSavingState ? t.saving : t.save}
                  </Button>
                  <Note
                    title={t.note}
                    noteType={NoteType.info}
                    intercomTarget={"node-setting-advanced-options-note-addmode"}
                  >
                    {nodeConfigurationState.status == 0 && (
                      <p>
                        <b>{t.customSettingsDisabledOnDisabledNodeNote}</b>
                      </p>
                    )}
                    <p>{t.info.importFailedPayments}</p>
                    <p>{t.pingNote}</p>
                    <p>{t.header.pingSystem}</p>
                    <p>{t.header.vectorPingSystem}</p>
                    <p>{t.header.ambossPingSystem}</p>
                  </Note>
                </div>
              </Collapse>
            </div>
          )}
        </Form>
      </div>
      <Modal
        title={"Are you sure?"}
        icon={<DeleteIconHeader />}
        onClose={handleConfirmationModalClose}
        show={showModalState}
      >
        <div className={styles.deleteConfirm}>
          <p>
            Deleting the node will prevent you from viewing it&apos;s data in Torq. Alternatively set node to disabled
            to simply stop the data subscription but keep data collected so far.
          </p>
          <p>
            This operation cannot be undone, type &quot;<span className={styles.red}>delete</span>&quot; to confirm.
          </p>
          <Form onSubmit={handleModalDeleteClick} intercomTarget={"delete-node-confirmation-form"}>
            <Input
              placeholder={t.header.typeDeleteHere}
              value={deleteConfirmationTextInputState}
              type={"text"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDeleteConfirmationTextInputChange(e.target.value)
              }
            />
            <Button
              type={"submit"}
              intercomTarget={"node-setting-delete-confirm-button"}
              buttonColor={ColorVariant.error}
              buttonPosition={ButtonPosition.fullWidth}
              icon={<DeleteIcon />}
              disabled={!deleteEnabled}
            >
              {t.delete}
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
});
export default NodeSettings;
